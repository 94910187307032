import { useMutation } from '@apollo/client'
import { MUTATION_SAVE_NAME } from '~/app/state/mutations'
import { useStartPageContext } from '~/modules/pages/state/context'
import useTracking from '~/shared/hooks/useTracking/useTracking'

export interface IChanges {
  id: string
  backgroundColor: string
  textColor: string
}

export default function () {
  const { startPageData } = useStartPageContext()
  const { trackThemeSelected } = useTracking()
  const [saveThemeToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_SAVE_NAME)

  const saveName = (name: string | null) => {
    if (name === null) {
      return
    }

    saveThemeToDB({
      variables: {
        organizationId: startPageData?.organizationId,
        pageId: startPageData?.id,
        name,
      },
    })
  }

  return {
    saveName,
    response: response ? { ...response } : null,
    mutationError,
    loading,
  }
}
