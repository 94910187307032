import React from 'react'
import { Blocks } from '~/modules/pages/blocks'
import AddBlockButton from '../AddBlockButton'
import { Wrapper, Category } from './styles'
import { useSplitEnabled } from '@bufferapp/features'

/**
 *
 */
function AddBlockList({
  category,
  onAddBlock,
}: {
  category: string
  onAddBlock: (type: string) => void
}): JSX.Element | null {
  const [blocks, setBlocks] = React.useState<string[]>([])
  const { isEnabled: isMailchimpEnabled } = useSplitEnabled(
    'build-week-2023-startpage_subscribe',
  )

  const showMailchimpBlock = isMailchimpEnabled

  React.useEffect(() => {
    if (!category) return

    const result: string[] = []

    Object.keys(Blocks).forEach((blockName: string) => {
      const block = (Blocks as any)[blockName]
      const { settings } = block

      if (settings.category && settings.category === category) {
        result.push(blockName)
      }
    })

    setBlocks(result)
  }, [category])

  return blocks?.length > 0 ? (
    <Wrapper>
      <Category type="p">{category}</Category>
      {blocks.map(
        (blockName: string, index: number) =>
          blockName !== 'Header' &&
          (blockName !== 'Mailchimp' || showMailchimpBlock) && (
            <AddBlockButton
              key={index}
              blockName={blockName}
              onAddBlock={onAddBlock}
            />
          ),
      )}
    </Wrapper>
  ) : null
}

export default AddBlockList
