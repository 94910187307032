import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Preview from '~/modules/preview'
import Editor from '~/modules/editor'
import Publisher from '~/modules/publisher'
import { useStartPageContext } from '~/modules/pages/state/context'
import styled from 'styled-components'
import { WrappedLoader } from '~/shared/components'

const Left = styled.div`
  background-color: #d9def7;
  flex-grow: 1;
`

function Builder() {
  const { startPageId } = useParams()
  const { startPageData, setStartPageIdFromPath } = useStartPageContext()

  useEffect(() => {
    if (!startPageId) return
    setStartPageIdFromPath(startPageId)
  }, [startPageId])

  return startPageData ? (
    <>
      <Left>
        <Publisher />
        <Preview />
      </Left>
      <Editor />
    </>
  ) : (
    <WrappedLoader />
  )
}

export default Builder
