import React from 'react'
import {
  ThumbnailButton,
  ThumbnailContainer,
  Circle,
  Quadrilateral,
} from './styles'

export function Thumbnail({
  selected,
  onSelect,
  theme,
}: {
  selected: string | null
  onSelect: (id: string) => void
  theme: any
}) {
  const buttonStyles = theme.blockStyles.find(
    (element: any) => element.blockType === 'BUTTON_LINK',
  )

  const textStyles = theme.blockStyles.find(
    (element: any) => element.blockType === 'TEXT',
  )

  const showBorder = theme?.globalStyles?.backgroundColor == '#FFFFFF'

  const onEnter = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const key = event.key || event.keyCode
      if (key === 'Enter' || key === 13) {
        onSelect(theme)
      }
    },
    [onSelect],
  )

  return (
    <ThumbnailButton
      selected={theme.id === selected}
      onClick={() => onSelect(theme.id)}
      role="button"
      tabIndex={0}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => onEnter(event)}
      data-testid="theme"
    >
      <ThumbnailContainer
        backgroundColor={theme?.globalStyles?.backgroundColor}
        showBorder={showBorder}
      >
        <Circle showBorder={showBorder} />
        <Quadrilateral
          button
          color={buttonStyles.backgroundColor}
          corner={buttonStyles.corners}
        />
        <Quadrilateral color={textStyles.backgroundColor} />
      </ThumbnailContainer>
    </ThumbnailButton>
  )
}
