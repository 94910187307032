import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { grayLighter } from '@bufferapp/ui/style/colors'

export const TemplateImage = styled.img`
  width: 68%;
  color: transparent;
  cursor: pointer;
`

export const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 48px;
  margin-bottom: 40px;
  width: 70%;
`

export const Button = styled.div`
  border-radius: 6px;

  p {
    margin-top: 16px;
  }
`

export const ImageWrapper = styled.div`
  border-radius: 10px;
  background-color: ${grayLighter};
  display: flex;
  align-items: end;
  justify-content: center;
  min-height: 100%;
`

export const EmptyMessage = styled(Text)`
  margin: 32px;
  font-size: 15px;
`
