import { useLazyQuery } from '@apollo/client'
import { QUERY_STATISTICS } from '../queries'

export type statisticsState = {
  allTimeViews: number
  dailyViews: number
  weeklyViews: number
}

export default function () {
  const [queryStatistics, { data, error, loading }] = useLazyQuery(
    QUERY_STATISTICS,
    {
      fetchPolicy: 'network-only',
    },
  )

  return {
    queryStatistics,
    statistics: data?.startPage.statistics || null,
    loading: loading || (!data && !error),
  }
}
