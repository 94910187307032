import styled, { css } from 'styled-components'
import { blueLighter, grayLight } from '@bufferapp/ui/style/colors'

export const ThumbnailButton = styled.div<{
  selected: boolean
}>`
  max-width: 100%;
  height: 114px;
  border-radius: 6px;
  padding: 2px;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 0 4px transparent;
  background-color: transparent;

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 0 4px ${blueLighter};
    `}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px ${blueLighter};
  }
`

export const ThumbnailContainer = styled.div<any>`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: ${(props) => props.backgroundColor};
  color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: ${(props) => (props.showBorder ? `1px solid ${grayLight}` : '')};
`

export const Circle = styled.div<any>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  border: ${(props) => (props.showBorder ? `1px solid ${grayLight}` : '')};
`

export const Quadrilateral = styled.div<any>`
  height: ${(props) => (props.button ? '12px' : '48px')};
  width: 56px;
  background: ${(props) => props.color};
  border-radius: ${(props) => props.corner || '4px'};
`
