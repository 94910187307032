import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, {
  type BugsnagErrorBoundary,
  type BugsnagPluginReactResult,
} from '@bugsnag/plugin-react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { Link } from '@bufferapp/ui'
import { Warning } from '@bufferapp/ui/Icon'
import { FeaturesWrapper } from '@bufferapp/features'
import {
  StartPageProvider,
  useStartPageContext,
} from '../modules/pages/state/context'
import {
  AccountProvider,
  useAccountContext,
} from '../modules/account/state/context'
import Onboarding from './views/onboarding'
import Builder from './views/builder'
import { NotificationProvider } from './state/notifications-context'
import { env } from '~/env'

Bugsnag.start({
  apiKey: '224cfe960229cdffa96eeb433ad19734',
  plugins: [new BugsnagPluginReact()],
  releaseStage: env.VITE_MODE,
})

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult
const ErrorBoundaryBugsnag: BugsnagErrorBoundary =
  plugin.createErrorBoundary(React)

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`

const ErrorBanner = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  margin: 32px;
  label {
    display: flex;
    margin-bottom: 16px;
    svg {
      margin-right: 8px;
    }
  }
`

function ErrorMessage({
  isPermissionError,
}: {
  isPermissionError: boolean
}): JSX.Element {
  if (isPermissionError) {
    return (
      <ErrorBanner>
        <Text htmlFor="foo" type="help" hasError>
          <Warning />
          You do not have permissions to access Start Page with this
          organization. Only admin users have access.
        </Text>
        <Text htmlFor="foo" type="p" hasError>
          Please contact the owner of your organization to grant you access.{' '}
          <Link
            href={
              'https://support.buffer.com/article/671-changing-user-permissions-in-your-organization#h_01EJGJ7PWG9MKCMJWHZJQ0A8FS'
            }
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {' '}
            This article{' '}
          </Link>{' '}
          will walk you through the steps.
        </Text>
      </ErrorBanner>
    )
  }
  return (
    <ErrorBanner>
      <Text htmlFor="foo" type="help" hasError>
        <Warning />
        Something went wrong or your organization might not have access to Start
        Page.
      </Text>
      <Text htmlFor="foo" type="help" hasError>
        Please reload or contact support if this continues.
      </Text>
    </ErrorBanner>
  )
}

function FallbackComponent(): JSX.Element {
  return <ErrorMessage isPermissionError={false} />
}

function ErrorBoundaryPermissionOrContext({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const [hasPermission, setHasPermission] = React.useState<boolean>(true)
  const { startPagesError, startPageError } = useStartPageContext()
  const { accountError, accountData } = useAccountContext()

  React.useEffect(() => {
    if (!accountData) return
    const { currentOrganization } = accountData
    setHasPermission(currentOrganization?.role === 'admin')
  }, [accountData])

  const hasError =
    startPageError || startPagesError || accountError || !hasPermission

  if (hasError) Bugsnag.notify(new Error('Permission or Account Error'))

  return hasError ? (
    <ErrorMessage isPermissionError={!hasPermission} />
  ) : (
    children
  )
}

/**
 * The App
 */
function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AccountProvider>
        <StartPageProvider>
          <ErrorBoundaryBugsnag FallbackComponent={FallbackComponent}>
            <ErrorBoundaryPermissionOrContext>
              <AppWrapper>
                <FeaturesWrapper>
                  <NotificationProvider>
                    <Routes>
                      <Route path="/onboarding" element={<Onboarding />} />
                      <Route path="/:startPageId" element={<Builder />}>
                        <Route path=":section" element={<Builder />} />
                      </Route>
                      <Route path="/" element={<Builder />} />
                    </Routes>
                  </NotificationProvider>
                </FeaturesWrapper>
              </AppWrapper>
            </ErrorBoundaryPermissionOrContext>
          </ErrorBoundaryBugsnag>
        </StartPageProvider>
      </AccountProvider>
    </BrowserRouter>
  )
}

export default App
