import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'
import { selectedColor } from '~/shared/colors'

export const ColorButton = styled.button<{ color: string }>`
  height: 100%;
  width: 100%;
  background-color: ${(props): string => props.color};
  border-radius: 50%;
  border: none;
  cursor: pointer;
`

export const Align = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    margin-bottom: 16px;
    color: ${grayDark};
  }
`

export const ColorOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ColorOption = styled.div<{ selected: boolean | undefined }>`
  transition-property: box-shadow;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  box-shadow: 0 0 0 4px transparent;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 2px;

  ${(props): FlattenSimpleInterpolation | undefined =>
    props.selected
      ? css`
          box-shadow: 0 0 0 4px ${selectedColor};
        `
      : undefined}

  &:focus {
    box-shadow: 0 0 0 4px ${selectedColor};
  }
  &:hover {
    box-shadow: 0 0 0 4px ${selectedColor};
  }

  button:focus {
    outline: none;
  }
`
