import React from 'react'
import styled from 'styled-components'
import { grayDark, grayLight } from '@bufferapp/ui/style/colors'
import Loader from '@bufferapp/ui/Loader'

const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`

const TableHeader = styled.thead`
  th {
    text-align: left;
    padding: 8px 16px;
    color: ${grayDark};
    font-weight: 500;
    font-size: 11px;
  }

  th:last-child {
    text-align: right;
  }

  tr {
    border-bottom: 1px solid ${grayLight};
  }
`

const TableBody = styled.tbody`
  td {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }

  tr {
    border-top: 1px solid ${grayLight};
  }
`

const EmptyMessage = styled.p`
  width: 100%;
  text-align: center;
  padding: 16px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

/**
 *
 */
function LinksTable({
  children,
  loading,
}: {
  children: any
  loading: boolean
}) {
  return (
    <Table>
      <TableHeader>
        <tr>
          <th>LINK</th>
          <th>CLICKS</th>
        </tr>
      </TableHeader>
      <TableBody>
        {children?.length > 0 ? (
          children
        ) : (
          <tr>
            <td colSpan={2}>
              {loading ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <EmptyMessage>No links available on the page</EmptyMessage>
              )}
            </td>
          </tr>
        )}
      </TableBody>
    </Table>
  )
}

export default LinksTable
