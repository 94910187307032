import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  type ReactNode,
} from 'react'
import { useSplitEnabled } from '@bufferapp/features'

import { useAccountContext } from '~/modules/account/state/context'
import { useStartPageContext } from '~/modules/pages/state/context'
import useModal from '~/shared/hooks/useModal'
import PageSelector from '~/modules/pages/components/PageSelector'
import CopyLinkButton from './components/CopyLinkButton'
import LinkCopied from './components/LinkCopied'
import PublishButton from './components/PublishButton'
import DomainConfirmation from './components/DomainConfirmation'
import NoChannelsAvailable from './components/NoChannelsAvailable'
import EmailNotVerified from './components/EmailNotVerified'
import updateModalStyle from './utils/updateModalStyle'

import {
  PublisherWrapper,
  ButtonWrapper,
  CopyLinkButtonWrapper,
  LinkCopiedWrapper,
} from './styles'

/**
 *
 */
function Publisher(): React.ReactElement {
  const wrapperRef = useRef<HTMLInputElement | null>(null)
  const { openModal, setOpenModal, Modal } = useModal()
  const [linkCopied, setLinkCopied] = useState(false)
  const { getChannelForStartPage, getUserType, accountData } =
    useAccountContext()
  const { startPageData } = useStartPageContext()
  const { isEnabled: isFeatureSplitEnabled } = useSplitEnabled(
    'LABSNPI-purchase-channel-at-limit',
  )
  const { resetModalStyle } = updateModalStyle()
  if (isFeatureSplitEnabled) {
    resetModalStyle()
  }

  const onLinkCopied = useCallback(() => {
    setLinkCopied(true)
  }, [setLinkCopied])

  const onPublishSkipped = useCallback(() => {
    setOpenModal(true)
  }, [setOpenModal])

  const onCloseModal = useCallback(() => {
    setOpenModal(false)
  }, [setOpenModal])

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key || event.keyCode
      if (key === 'Escape' || key === 27) {
        document.getElementById('copy-link-button')?.focus()
        setLinkCopied(false)
      }
    },
    [setLinkCopied],
  )

  const onClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!wrapperRef.current) return
      const clickedOutside = !wrapperRef.current.contains(event.target as Node)

      if (clickedOutside) {
        setLinkCopied(false)
      }
    },
    [setLinkCopied],
  )

  useEffect(() => {
    if (linkCopied) {
      document.addEventListener('click', onClickOutside, true)
      document.addEventListener('keydown', onEscape, true)
    } else {
      document.removeEventListener('click', onClickOutside, true)
      document.removeEventListener('keydown', onEscape, true)
    }
  }, [linkCopied])

  const renderModalContent = (): ReactNode | null => {
    if (!startPageData) return null
    const startPageChannel = getChannelForStartPage(startPageData.id)
    const userType = getUserType()
    const { canPublishStartPage } = startPageChannel || {}
    const canSeeDomainStep =
      isFeatureSplitEnabled && !canPublishStartPage && userType === 'OB_PAID'

    if (!accountData?.hasVerifiedEmail) {
      return <EmailNotVerified />
    }

    return canPublishStartPage || canSeeDomainStep || !startPageChannel ? (
      <DomainConfirmation withMessage={startPageChannel} />
    ) : (
      <NoChannelsAvailable userType={userType} onClose={onCloseModal} />
    )
  }

  return (
    <PublisherWrapper>
      <PageSelector />
      <ButtonWrapper ref={wrapperRef}>
        <CopyLinkButtonWrapper>
          <CopyLinkButton onLinkCopied={onLinkCopied} />
          {linkCopied && (
            <LinkCopiedWrapper>
              <LinkCopied />
            </LinkCopiedWrapper>
          )}
        </CopyLinkButtonWrapper>
        <PublishButton onSkipped={onPublishSkipped} />
      </ButtonWrapper>
      {openModal && <Modal>{renderModalContent()}</Modal>}
    </PublisherWrapper>
  )
}

export default Publisher
