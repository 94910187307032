import React, { useState, useCallback } from 'react'

import StepA from './StepA'
import StepB from './StepB'
import StepC from './StepC'

import { Wrapper } from './styles'

/**
 *
 */
function DomainConfirmation({ withMessage }: { withMessage: boolean }) {
  const [step, setStep] = useState(0)

  const onNext = useCallback(() => {
    setStep((step) => step + 1)
  }, [setStep])

  return (
    <Wrapper>
      {step === 0 && <StepA onNext={onNext} withMessage={withMessage} />}
      {step === 1 && <StepB onNext={onNext} />}
      {step === 2 && <StepC />}
    </Wrapper>
  )
}

export default DomainConfirmation
