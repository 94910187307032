import styled from 'styled-components'
import { white, grayLight, grayDark } from '@bufferapp/ui/style/colors'

export const List = styled.ul`
  list-style: none;
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${grayLight};
  margin: 0px 16px 8px 16px;
  height: 64px;
  border-radius: 8px;
  background-color: ${white};
`

export const DragDropIcon = styled.div<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? grayLight : grayDark)};
  padding: 12px;
`
