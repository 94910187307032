import React from 'react'
import Tag from '@bufferapp/ui/Tag'
import Text from '@bufferapp/ui/Text'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import useToggle from '~/shared/hooks/useToggle'

import {
  Wrapper,
  Image,
  TextWrapper,
  MessageTitle,
  MessageContent,
  ShowMore,
  Benefits,
  Benefit,
} from './styles'

const IMAGE_URL = 'https://buffer-start-page.s3.amazonaws.com/sp-as-channel.png'

/**
 *
 */
function Message() {
  const { value: isVisible, onToggle } = useToggle(false)

  return (
    <Wrapper>
      <Image
        src={IMAGE_URL}
        alt="An image showing the different types of channels we have at Buffer"
      />
      <TextWrapper>
        <MessageTitle>
          <Tag color="green">New</Tag>
          Start Page is now a channel!
        </MessageTitle>
        <MessageContent>
          <Text type="p">
            By publishing your page, it becomes one of your channels and it
            takes one of the slots available.
            <ShowMore onClick={onToggle}>Show Benefits</ShowMore>
          </Text>
        </MessageContent>
        <Benefits visible={isVisible}>
          <Benefit>
            <CheckmarkIcon size="large" />
            Publish multiple Start Pages
          </Benefit>
          <Benefit>
            <CheckmarkIcon size="large" />
            Manage each channel in one place
          </Benefit>
          <Benefit>
            <CheckmarkIcon size="large" />
            Schedule Start Page posts (coming soon)
          </Benefit>
        </Benefits>
      </TextWrapper>
    </Wrapper>
  )
}

export default Message
