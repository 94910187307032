import React, { useState } from 'react'
import { useStartPageContext } from '~/modules/pages/state/context'
import useSaveName from '~/app/state/hooks/useSaveName'
import { InputWithLabel } from '~/shared/components/FormElements'
import { FieldSet } from '~/shared/components'
import { NameInputWrapper } from './styles'

export function NameChange() {
  const { saveName } = useSaveName()
  const { startPageData } = useStartPageContext()

  const [name, setName] = useState(startPageData?.title || '')

  return (
    <FieldSet>
      <NameInputWrapper>
        <InputWithLabel
          label="Page name"
          inputValue={name}
          setInputValue={setName}
          saveOnBlur={() => saveName(name)}
        />
      </NameInputWrapper>
    </FieldSet>
  )
}
