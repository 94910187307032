import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import Tag from '@bufferapp/ui/Tag'
import { white, grayDark, grayDarker } from '@bufferapp/ui/style/colors'

import BlockIcon from '../BlockIcon'

export const Wrapper = styled.button<{ grayedOut: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${white};
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 12px 0px;
  opacity: ${(props) => (props.grayedOut ? '30%' : '100%')};
`

export const IconWrapper = styled(BlockIcon)`
  width: 56px;
  height: 56px;
  margin-right: 16px;
  flex-shrink: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
`

export const Label = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${grayDarker};
`

export const Description = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: ${grayDark};
`

export const StyledTag = styled(Tag)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-left: 5px;
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`
export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
