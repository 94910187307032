import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Add, Appearance, Layout, Gear, Stats } from '@bufferapp/ui/Icon'
import { Blocks } from '~/modules/pages/blocks'
import { BLOCK_CATEGORIES } from '~/modules/pages'
import type { ActiveTabState } from '../../index'

import { Wrapper, OptionsList, OptionButton, StyledTag } from './styles'

interface NavBarProps {
  activeTab: ActiveTabState
  onNavBarOptionClick: (optionId: ActiveTabState) => void
}

interface OptionItem {
  id: ActiveTabState
  icon: JSX.Element
  label: 'Add Block' | 'Layout' | 'Appearance' | 'Settings' | 'Statistics'
}

type NavBarOptions = OptionItem[]

const navBarOptions: NavBarOptions = [
  { id: 'appearance', icon: <Appearance />, label: 'Appearance' },
  { id: 'layout', icon: <Layout />, label: 'Layout' },
  { id: 'add', icon: <Add />, label: 'Add Block' },
  { id: 'statistics', icon: <Stats />, label: 'Statistics' },
  { id: 'settings', icon: <Gear />, label: 'Settings' },
]

/**
 *
 */
function NavBar({ activeTab, onNavBarOptionClick }: NavBarProps): JSX.Element {
  const [newBlocks, setNewBlocks] = React.useState(0)

  const navigate = useNavigate()

  React.useEffect(() => {
    let count = 0

    Object.keys(Blocks).forEach((blockName: string) => {
      const block = (Blocks as any)[blockName]
      const { settings } = block

      if (settings?.category === BLOCK_CATEGORIES.RECENTLY) {
        count++
      }
    })

    setNewBlocks(count)
  }, [])

  return (
    <Wrapper>
      <OptionsList>
        {navBarOptions.map((option: OptionItem) => (
          <li key={option.id}>
            <OptionButton
              active={activeTab === option.id}
              onClick={(): void => {
                onNavBarOptionClick(option.id)
                navigate(`${option.id}`)
              }}
            >
              {option.icon}
              {option.label}
              {option.id === 'add' && newBlocks > 0 && (
                <StyledTag color="green">{newBlocks}</StyledTag>
              )}
            </OptionButton>
          </li>
        ))}
      </OptionsList>
    </Wrapper>
  )
}

export default NavBar
