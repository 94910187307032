import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  box-shadow: -3px 4px 4px rgb(0 0 0 / 15%);
`

export const CloseTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border-bottom: 30px solid #f5f5f5;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    width: 100px;
    transform: rotate(-90deg) translate(0, -15px);
    background: transparent;
    border-top: none;
    position: absolute;
    cursor: pointer;

    svg {
      position: absolute;
      top: 8px;
      right: 15px;
    }
  }
`

interface LeftProps {
  collapsed: boolean
}

export const Left = styled.div<LeftProps>`
  width: ${(props) => (props.collapsed ? '0px' : '450px')};
  background-color: #f5f5f5;
  overflow: auto;
  padding-bottom: 8px;

  ${(props) =>
    !props.collapsed &&
    css`
      position: relative;
    `}
`
