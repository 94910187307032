import React from 'react'
import { Trash, Hide, View } from '@bufferapp/ui/Icon'
import type { AnyBlock } from '@buffer-mono/sp-display'
import useHideBlock from '~/app/state/hooks/useHideBlock'
import { IconButton, ActionsWrapper } from './styles'

/**
 *
 */
function EditBlockActions({
  setSelectedBlockId,
  block,
  setOpenModal,
  shouldDisplayDeleteAction,
  shouldDisplayHideOrShowAction,
}: {
  setSelectedBlockId: (id: string) => void
  block: AnyBlock
  setOpenModal: (openModal: boolean) => void
  shouldDisplayDeleteAction: boolean
  shouldDisplayHideOrShowAction: boolean
}): JSX.Element {
  const { hideOrShowBlock, response: responseHide } = useHideBlock({
    blockId: block.id,
  })

  return (
    <ActionsWrapper>
      {shouldDisplayDeleteAction && (
        <IconButton
          data-testid="delete-button"
          onClick={(event: any) => {
            event.stopPropagation()
            setSelectedBlockId(block.id)
            setOpenModal(true)
          }}
          className="hidden"
        >
          <Trash title="delete block" />
        </IconButton>
      )}
      {shouldDisplayHideOrShowAction && (
        <IconButton
          data-testid="visibility-button"
          onClick={(event: any) => {
            event.stopPropagation()
            hideOrShowBlock()
          }}
          className={block.hidden ? 'visible' : 'hidden'}
        >
          {block?.hidden ? (
            <Hide title="hide block" />
          ) : (
            <View title="display block" />
          )}
        </IconButton>
      )}
    </ActionsWrapper>
  )
}

export default EditBlockActions
