import React from 'react'
import { useStartPageContext } from '~/modules/pages/state/context'
import usePublish from '~/app/state/hooks/usePublish'
import { DomainInput } from '~/shared/components/FormElements'
import { FieldSet } from '~/shared/components'
import Text from '@bufferapp/ui/Text'
import {
  TipContent,
  TipText,
  Label,
} from '~/modules/pages/blocks/Video/Tip/styles'
import { OpenNew } from '@bufferapp/ui/Icon'
import { HC_UTM_PARAMS } from '~/shared/constants'
import { Space } from '../Unpublish'
import { DomainInputWrapper, Image, TipWrapper } from './styles'

export function DomainChange(): JSX.Element {
  const { publishChanges, loading, error } = usePublish()
  const { startPageData, hasDomain } = useStartPageContext()
  const { domain, isPublished } = startPageData || {}

  return (
    <FieldSet>
      <DomainInputWrapper>
        <Text htmlFor="domain-input" type="label">
          Page Domain
        </Text>
        {isPublished || hasDomain ? (
          <DomainInput
            domain={hasDomain ? domain : ''}
            onContinue={(newDomain): void => publishChanges(newDomain)}
            buttonLabel="Save and Publish"
            loading={loading}
            error={error}
          />
        ) : (
          <>
            <Space />
            <Text type="p">
              Your Start Page isn't published yet. Make sure to publish it in
              order to set a domain name.
            </Text>
          </>
        )}
      </DomainInputWrapper>
      <TipWrapper
        as="a"
        href={`https://support.buffer.com/article/664-using-start-page-with-buffer?${HC_UTM_PARAMS}#h_01HB7ZYT2WJ1CQAAE32285ZW0G`}
        target="_blank"
        rel="noopener"
      >
        <TipContent>
          <Image
            src="https://buffer-start-page.s3.amazonaws.com/tip-custom-domain.png"
            alt=""
          />
          <TipText>
            <Label>Buffer Help Center</Label>
            <Text>Learn how to set up your custom domain</Text>
          </TipText>
        </TipContent>
        <div>
          <OpenNew />
        </div>
      </TipWrapper>
    </FieldSet>
  )
}
