import React, { useState, useCallback, useEffect } from 'react'
import { Add } from '@bufferapp/ui/Icon'

import { Wrapper, Button, Picker, Color } from './styles'

/**
 *
 */
function ColorPicker({
  currentColor,
  onSelectColor,
}: {
  currentColor: string | null
  onSelectColor: (color: string, fontColor?: string) => void
}) {
  const [color, setColor] = useState<string | null>()

  useEffect(() => {
    setColor(currentColor)
  }, [currentColor])

  const onChange = useCallback(
    (event) => {
      const newColor = event.target.value
      setColor(newColor)
      onSelectColor(newColor)
    },
    [setColor],
  )

  return (
    <Wrapper>
      <Picker
        type="color"
        value={color || '#000000'}
        onChange={onChange}
        aria-label="Choose a color for all buttons"
      />
      <Button>{color ? <Color color={color} /> : <Add size="medium" />}</Button>
    </Wrapper>
  )
}

export default ColorPicker
