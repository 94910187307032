import React, { useState, useEffect } from 'react'
import Loader from '@bufferapp/ui/Loader'

import type { Blocks } from '@buffer-mono/sp-display'
import { Header } from '~/shared/components'
import EditBlockList from '~/modules/pages/components/EditBlockList'
import { useStartPageContext } from '~/modules/pages/state/context'
import { useNotificationContext } from '~/app/state/notifications-context'
import useDeleteBlock from '~/app/state/hooks/useDeleteBlock'
import useHideBlock from '~/app/state/hooks/useHideBlock'
import EditPanel from '../EditPanel'
import { LoaderWrapper } from './styles'

export type SelectedBlockId = string
export type SetSelectedBlockId = React.Dispatch<
  React.SetStateAction<SelectedBlockId>
>
export type Editing = boolean
export type SetEditing = React.Dispatch<React.SetStateAction<Editing>>

function Layout(): JSX.Element {
  const { startPageData } = useStartPageContext()
  const [editing, setEditing] = useState<Editing>(false)
  const [selectedBlockId, setSelectedBlockId] = useState<SelectedBlockId>('')
  const blocks: Blocks = startPageData?.blocks || []
  const { setNotificationOptions } = useNotificationContext()

  React.useEffect(() => {
    setEditing(false)
    setSelectedBlockId('')
  }, [startPageData?.id])

  const {
    deleteBlock,
    response: responseDelete,
    mutationError: mutationErrorDelete,
    loading: saveChangesLoading,
  } = useDeleteBlock({ blockId: selectedBlockId })

  const {
    hideOrShowBlock,
    response: responseHide,
    mutationError: mutationErrorHide,
    loading: hideLoading,
  } = useHideBlock({
    blockId: selectedBlockId,
  })

  useEffect(() => {
    if (mutationErrorHide) {
      setNotificationOptions({ message: 'Whoops, something went wrong!' })
    } else if (responseHide) {
      setNotificationOptions({
        message: 'The block has been hidden successfully!',
      })
    }
  }, [hideLoading])

  useEffect(() => {
    if (mutationErrorDelete) {
      setNotificationOptions({ message: 'Whoops, something went wrong!' })
    } else if (responseDelete) {
      setNotificationOptions({
        message: 'The block has been deleted successfully!',
      })
    }
  }, [saveChangesLoading])

  if (blocks.length === 0) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  const onReturnClick = () => {
    setEditing(false)
    setSelectedBlockId('')
  }

  const selectedBlock = blocks.find((block) => block.id === selectedBlockId)

  return (
    <>
      {!editing && (
        <>
          <Header>
            <h2>Your Site Layout</h2>
          </Header>
          <EditBlockList
            setEditing={setEditing}
            setSelectedBlockId={setSelectedBlockId}
            deleteBlock={deleteBlock}
            selectedBlock={selectedBlock}
          />
        </>
      )}
      {editing && (
        <EditPanel
          block={selectedBlock}
          onReturnClick={onReturnClick}
          deleteBlock={deleteBlock}
          hideOrShowBlock={hideOrShowBlock}
        />
      )}
    </>
  )
}

export default Layout
