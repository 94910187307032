import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  max-width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px;
  text-align: center;
  color: ${grayDarker};
`

export const Image = styled.img`
  width: 200px;
  height: 100%;
  margin-bottom: 32px;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 12px;
`

export const Message = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
`

export const Action = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > :last-child {
    margin-left: 12px;
  }
`
