import styled from 'styled-components'
import {
  blue,
  grayLight,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  padding: 40px;
  border-top: 1px solid ${grayLight};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const Image = styled.img`
  width: 75px;
  height: 100%;
`

export const TextWrapper = styled.div`
  width: 400px;
  padding: 0px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    text-align: left;
  }

  & > * {
    margin-bottom: 8px;
  }
`

export const MessageTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${grayDarker};

  span {
    margin: 0px 0px 8px 0px;
    font-size: 10px;
    padding: 2px 8px;
    display: block;
    width: 36px;
  }
`

export const MessageContent = styled.div`
  display: flex;
  flex-direction: row;
  color: ${grayDark};
`

export const ShowMore = styled.span`
  cursor: pointer;
  color: ${grayDarker};
  text-decoration: underline;
  font-size: 14px;
  margin: 0px 4px;
`

export const Benefits = styled.ul<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  color: ${grayDarker};
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const Benefit = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 10px;
    color: ${blue};
  }
`
