import { gql } from '@apollo/client'

export const QUERY_TEMPLATES = gql`
  query startPageTemplates {
    startPageTemplates {
      templates {
        id
        name
        categories
        thumbnailUrl
      }
      categories
    }
  }
`
