import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '@bufferapp/ui/Text'
import Input from '@bufferapp/ui/Input'
import Button from '@bufferapp/ui/Button'

import useModal from '~/shared/hooks/useModal'
import {
  useStartPageContext,
  START_PAGES_LIMIT,
} from '~/modules/pages/state/context'
import { useAccountContext } from '~/modules/account/state/context'
import Gallery from '~/modules/templates/components/Gallery'
import { WrappedLoader } from '~/shared/components'
import { useTemplates } from '~/modules/templates/hooks/useTemplates'
import type { IStartPageTemplate } from '~/modules/templates'

import {
  OnboardingWrapper,
  CategoryList,
  CategoryItem,
  ModalWrapper,
  TextWrapper,
  ContentWrapper,
} from './styles'

/**
 *
 */
function Onboarding(): JSX.Element {
  const [canCreateStartPages, setCanCreateStartPages] = useState<boolean>(false)
  const [templateId, setTemplateId] = useState<string | null>(null)
  const [selectedName, setSelectedName] = useState<string | null>(null)
  const [selectedCategory, setSelectedCategory] =
    useState<string>('All templates')
  const { openModal, setOpenModal, Modal } = useModal()

  const { accountData } = useAccountContext()
  const { queryTemplates, templates, categories } = useTemplates()
  const { createNewStartPage, startPagesData } = useStartPageContext()

  const navigate = useNavigate()

  useEffect(() => {
    queryTemplates()

    const queryParams = new URLSearchParams(window.location.search)
    setTemplateId(queryParams.get('preset'))
  }, [queryTemplates])

  useEffect(() => {
    if (!startPagesData) return

    const maxStartPages = START_PAGES_LIMIT
    const belowLimit = startPagesData.length < maxStartPages
    setCanCreateStartPages(belowLimit)

    if (!belowLimit) {
      navigate('/')
    }
  }, [startPagesData, navigate])

  useEffect(() => {
    if (!templateId || !canCreateStartPages) return

    if (!selectedName) {
      setOpenModal(true)
      return
    }

    createNewStartPage({
      template: templateId,
      title: selectedName || undefined,
    })
  }, [templateId, selectedName])

  const onSelectTemplate = React.useCallback(
    (template: IStartPageTemplate) => {
      setTemplateId(template.id)
    },
    [setTemplateId],
  )

  const onSelectName = React.useCallback(
    (newName: string) => {
      setSelectedName(newName)
      setOpenModal(false)
    },
    [selectedName, setOpenModal],
  )

  const onEnter = React.useCallback(
    (event: React.KeyboardEvent, category: string) => {
      const key = event.key || event.keyCode
      if (key === 'Enter' || key === 13) {
        setSelectedCategory(category)
      }
    },
    [setSelectedCategory],
  )

  const displayOnboarding =
    accountData && categories && templates && canCreateStartPages

  return displayOnboarding ? (
    <OnboardingWrapper>
      <Text type="h1">Choose a starting template!</Text>
      <Text type="p" className="subheading">
        If you don&apos;t find a template that matches your brand you can
        customize it on the next step
      </Text>
      <CategoryList>
        {categories.map((category, index) => (
          <CategoryItem
            key={index}
            selected={category === selectedCategory}
            onClick={(): void => setSelectedCategory(category)}
            onKeyDown={(event: React.KeyboardEvent): void =>
              onEnter(event, category)
            }
            role="button"
            tabIndex={0}
          >
            {category}
          </CategoryItem>
        ))}
      </CategoryList>
      <Gallery
        templates={templates}
        categories={categories}
        category={selectedCategory}
        onSelect={onSelectTemplate}
      />
      {openModal && (
        <Modal>
          <NameSelection onSelect={onSelectName} />
        </Modal>
      )}
    </OnboardingWrapper>
  ) : (
    <WrappedLoader />
  )
}

/**
 *
 */
function NameSelection({
  onSelect,
}: {
  onSelect: (name: string) => void
}): JSX.Element {
  const [name, setName] = useState<string | null>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setName(value.trim().toLowerCase())
  }

  const onNext = (): void => {
    if (!name) return
    onSelect(name)
  }

  return (
    <ModalWrapper>
      <TextWrapper>
        <Text type="h3">Name your Start Page to start editing</Text>
        <Text type="p">
          Your Start Page name only shows on your pages list within Buffer
        </Text>
      </TextWrapper>
      <ContentWrapper>
        <Input
          onChange={onChange}
          name="start-page-name"
          placeholder="Your page name"
        />
        <Button
          type="primary"
          onClick={onNext}
          label="Next"
          disabled={!name}
          fullWidth
        />
      </ContentWrapper>
    </ModalWrapper>
  )
}

export default Onboarding
