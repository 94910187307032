import React from 'react'
import styled from 'styled-components'
import {
  grayLighter,
  grayLight,
  grayDarker,
  grayDark,
} from '@bufferapp/ui/style/colors'
import shortenText from '~/shared/utils/shortenText'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 340px;
  overflow: hidden;
  padding: 16px;
`

export const Order = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${grayLighter};
  border: 1px solid ${grayLight};
  border-radius: 5px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px;
  color: ${grayDarker};
`

export const Type = styled.span`
  font-weight: 500;
  font-size: 14px;
  padding-right: 4px;
`

export const URL = styled.p`
  font-size: 12px;
  color: ${grayDark};
`

/**
 *
 */
function Link({
  order,
  type,
  content,
  url,
}: {
  order: number
  type: string
  content: string
  url: string
}) {
  return (
    <Wrapper>
      <Order>{order}</Order>
      <Info>
        <p>
          <Type>{type}:</Type>
          {shortenText(content, 20)}
        </p>
        <URL>{shortenText(url, 25)}</URL>
      </Info>
    </Wrapper>
  )
}

export default Link
