import React from 'react'
import Tooltip from '@bufferapp/ui/Tooltip'
import Tag from '@bufferapp/ui/Tag'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'

import { useStartPageContext } from '~/modules/pages/state/context'
import type { Blocks, AnyBlock } from '@buffer-mono/sp-display'

import { type BlockAPI, getBlockByBlockName } from '~/modules/pages/blocks'
import { useAccountContext } from '~/modules/account/state/context'

import {
  Wrapper,
  IconWrapper,
  ContentWrapper,
  Label,
  Description,
  StyledTag,
  TitleWrapper,
  DescriptionWrapper,
} from './styles'

function uniqueBlockAlreadyExists(
  blockToAdd: BlockAPI,
  existingBlocks?: Blocks,
): boolean {
  if (!existingBlocks || !blockToAdd.settings.unique) return false

  const found = existingBlocks.find(
    (existingBlock: AnyBlock) =>
      existingBlock.__typename === blockToAdd.settings.databaseType,
  )

  return !!found
}

/**
 *
 */
function AddBlockButton({
  blockName,
  onAddBlock,
}: {
  blockName: string
  onAddBlock: (type: string) => void
}): JSX.Element | null {
  const { accountData } = useAccountContext()
  const isNewBuffer = accountData?.currentOrganization?.isOneBufferOrganization

  const { startPageData } = useStartPageContext()
  const existingBlocks = startPageData?.blocks

  const block: BlockAPI | null = getBlockByBlockName(blockName)
  if (!block || !block.addButtonSettings) return null
  const grayedOut = uniqueBlockAlreadyExists(block, existingBlocks)

  const { settings, addButtonSettings } = block
  const { comingSoon, isNew, icon, onlyForNewBuffer } = settings
  const { Component: Icon, color } = icon
  const { label, description } = addButtonSettings

  const shouldShowFlashIcon = onlyForNewBuffer && !isNewBuffer

  return (
    <Tooltip
      label={grayedOut ? 'This block can only be added once' : undefined}
      position={grayedOut ? 'left' : undefined}
    >
      <Wrapper
        grayedOut={grayedOut}
        onClick={(): void | null => (grayedOut ? null : onAddBlock(blockName))}
        data-testid="add-block-button-wrapper"
      >
        <IconWrapper color={color}>
          <Icon title={`A ${label} block`} />
        </IconWrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Label type="h2">{label}</Label>
            {shouldShowFlashIcon && (
              <Tag color="purpleLighter">
                <FlashIcon data-testid="upgrade-icon" color="purple" />
              </Tag>
            )}
          </TitleWrapper>
          {/* if upgrade icon is displayed the tags for Comming soon or New should not be displayed */}
          <DescriptionWrapper>
            <Description type="p">{description}</Description>
            {!shouldShowFlashIcon && comingSoon && (
              <StyledTag color="green">Soon</StyledTag>
            )}
            {!shouldShowFlashIcon && isNew && (
              <StyledTag color="green">New</StyledTag>
            )}
          </DescriptionWrapper>
        </ContentWrapper>
      </Wrapper>
    </Tooltip>
  )
}

export default AddBlockButton
