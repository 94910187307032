import type { IStartPageTemplate } from '~/modules/templates/index'

/**
 *
 */
export default function getTemplatesForCategory(
  templates: IStartPageTemplate[],
  category: string,
) {
  const result: IStartPageTemplate[] = []

  templates.forEach((template: IStartPageTemplate) => {
    const { categories } = template
    if (categories && categories.includes(category)) {
      result.push(template)
    }
  })

  return result
}
