import { gql } from '@apollo/client'

export const ACCOUNT_INITIATE_EMAIL_VERIFICATION = gql`
  mutation AccountInitiateEmailVerification($channelId: String) {
    accountInitiateEmailVerification(channelId: $channelId) {
      ... on AccountInitiateEmailVerificationResponse {
        success
      }
      ... on AccountInitiateEmailVerificationError {
        userFriendlyMessage
      }
    }
  }
`
