import React from 'react'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Count = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
`

/**
 *
 */
function PageViews({ count, period }: { count: string; period: string }) {
  return (
    <Wrapper data-testid="page-views">
      <Count>{count}</Count>
      <Text type="p">Page Views</Text>
      <Text type="p">{period}</Text>
    </Wrapper>
  )
}

export default PageViews
