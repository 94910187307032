import React from 'react'
import { render } from 'react-dom'
import 'regenerator-runtime/runtime'
import { env } from '~/env'
import { setCFWorkerTrackingUrl } from '@buffer-mono/sp-display'
setCFWorkerTrackingUrl(env.VITE_CF_WORKER_TRACKING_URL)

import App from '../app/App'
import { APIGatewayProvider, MockedProvider } from '../app/providers'

const Provider =
  env.VITE_MODE === 'mocked' ? MockedProvider : APIGatewayProvider

render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById('root'),
)
