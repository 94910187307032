import React from 'react'
import { Header } from '~/shared/components'
import { DomainChange } from '~/modules/pages/components/DomainChange'
import { NameChange } from '~/modules/pages/components/NameChange'
import { Unpublish } from '~/modules/pages/components/Unpublish'

function Settings() {
  return (
    <>
      <Header>
        <h2>Settings</h2>
      </Header>
      <DomainChange />
      <NameChange />
      <Unpublish />
    </>
  )
}

export default Settings
