import { useLazyQuery } from '@apollo/client'
import { QUERY_TEMPLATES } from '../state/queries'
import type { IStartPageTemplate } from '../index'

export function useTemplates() {
  const [queryTemplates, { data, error, loading }] =
    useLazyQuery(QUERY_TEMPLATES)

  return {
    queryTemplates,
    templates:
      (data?.startPageTemplates?.templates as IStartPageTemplate[]) || null,
    categories: (data?.startPageTemplates?.categories as [string]) || null,
    loading: loading || (!data && !error),
    error,
  }
}
