import styled from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

export const PublisherWrapper = styled.div`
  margin-top: auto;
  padding: 8px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  div:only-child {
    margin-left: auto;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`

export const CopyLinkButtonWrapper = styled.div`
  position: relative;
`

export const LinkCopiedWrapper = styled.div`
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid ${grayLight};
  border-radius: 8px;
  z-index: 1000;

  & > * {
    border-radius: 6px;
  }
`
