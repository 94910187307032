import React from 'react'
import Button from '@bufferapp/ui/Button'

import getAppURL from '~/shared/utils/getAppURL'

import useTracking from '~/shared/hooks/useTracking/useTracking'
import { useStartPageContext } from '~/modules/pages/state/context'
import {
  Wrapper,
  Image,
  Title,
  Message,
  Action,
  ButtonsWrapper,
} from './styles'

/**
 *
 */
const IMAGE_URL =
  'https://buffer-start-page.s3.amazonaws.com/sp-as-channel-limit.png'
const TITLE_A = "You have hit your plan's limit"
const TITLE_B = "You'll need to add an extra channel"
const SUPPORT_URL = `https://support.buffer.com/?openContact=true&utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more`
const ACCOUNT_URL = getAppURL('account')

/**
 *
 */
const TEXTS = {
  OB_FREE: {
    title: TITLE_A,
    message: `Start Page now counts as a channel and you currently don't have any
      available slots in your plan. To publish please `,
    action: 'upgrade your plan or disconnect an existing channel.',
    primaryCTA: 'Upgrade plan',
    primaryURL: `${ACCOUNT_URL}/billing`,
    secondaryCTA: 'Disconnect a channel',
    secondaryURL: `${ACCOUNT_URL}/channels`,
  },
  OB_PAID: {
    title: TITLE_B,
    message: `You've connected all the channels available in your plan. To publish
    this Start Page you'll need to add an extra channel.`,
    action: null,
    primaryCTA: 'Add more channels',
    primaryURL: `${ACCOUNT_URL}/channels`,
    secondaryCTA: null,
    secondaryURL: null,
  },
  MP: {
    title: TITLE_A,
    message: `Start Page now counts as a channel and you currently don't have any
      more available in your plan. To publish please `,
    action: 'reach out to Customer Support.',
    primaryCTA: 'Contact Support',
    primaryURL: SUPPORT_URL,
    secondaryCTA: null,
    secondaryURL: null,
  },
  MOBILE: {
    title: TITLE_A,
    message: `Start Page now counts as a channel and you currently don't have any
    available slots in your plan. To publish `,
    action: "you'll have to add a new channel from the Buffer Mobile App 📱",
    primaryCTA: 'Okay, got it!',
    primaryURL: null,
    secondaryCTA: null,
    secondaryURL: null,
  },
}

/**
 *
 */
function NoChannelsAvailable({
  userType,
  onClose,
}: {
  userType: 'OB_FREE' | 'OB_PAID' | 'MOBILE' | 'MP'
  onClose: () => void
}) {
  const { startPagesData } = useStartPageContext()
  const { trackPlanLimitHit } = useTracking()

  React.useEffect(() => {
    trackPlanLimitHit({
      createdStartPages: startPagesData.length || 0,
    })
  }, [])

  const onGoTo = React.useCallback((url: string | null) => {
    url ? window.location.assign(url) : onClose()
  }, [])

  const textValues = TEXTS[userType]

  return (
    <Wrapper>
      <Image src={IMAGE_URL} alt="An image showing a warning sign" />
      <Title>{textValues.title}</Title>
      <Message>
        {textValues.message}
        <Action>{textValues.action}</Action>
      </Message>
      <ButtonsWrapper>
        <Button
          type="primary"
          onClick={() => onGoTo(textValues.primaryURL)}
          label={textValues.primaryCTA}
        />
        {textValues.secondaryCTA && (
          <Button
            type="secondary"
            onClick={() => onGoTo(textValues.secondaryURL)}
            label={textValues.secondaryCTA}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default NoChannelsAvailable
