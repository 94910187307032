import React from 'react'
import Text from '@bufferapp/ui/Text'

import { BASE_URL } from '~/shared/constants'
import { useStartPageContext } from '~/modules/pages/state/context'
import CopyLinkButton from '~/modules/publisher/components/CopyLinkButton'
import { Wrapper, Image, DomainWrapper, Domain } from './styles'

const BG_IMAGE = 'https://buffer-start-page.s3.amazonaws.com/page-published.png'

/**
 *
 */
function StepB({ onNext }: { onNext: () => void }) {
  const { startPageData } = useStartPageContext()
  const { domain } = startPageData || {}

  return (
    <Wrapper>
      <Image src={BG_IMAGE} alt="" />
      <Text type="h3">Congratulations 🎉 Your Start Page is live!</Text>
      <Text type="p">
        You’ve turned your idea into a reality. Most people never get that far!
        Let’s drive some traffic adding your Start Page link to your social
        media bios.
      </Text>
      <DomainWrapper>
        <Domain>{`${domain}${BASE_URL}`}</Domain>
        <CopyLinkButton primary onLinkCopied={onNext} />
      </DomainWrapper>
    </Wrapper>
  )
}

export default StepB
