import styled from 'styled-components'
import { white, grayDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 131.7deg at 51.79% 50%,
    #67be47 -19.67deg,
    #3fac6f 7.32deg,
    #30a389 38.37deg,
    #23768d 67.99deg,
    #284a8d 104.53deg,
    #6a307c 130.31deg,
    #bd4691 161.84deg,
    #f74765 192.62deg,
    #fa3820 212.52deg,
    #f9411e 246.03deg,
    #f9571b 276.55deg,
    #fed754 312.16deg,
    #67be47 340.33deg,
    #3fac6f 367.32deg
  );
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Button = styled.div`
  border: none;
  border-radius: 50%;
  background-color: ${white};
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;

  svg {
    color: ${grayDarker};
    fill: ${grayDarker};
  }
`

export const Picker = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`

export const Color = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`
