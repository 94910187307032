import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Wrapper, Image, Title, Message } from './styles'

import { useStartPageContext } from '~/modules/pages/state/context'
import { ACCOUNT_INITIATE_EMAIL_VERIFICATION } from '~/modules/account/state/mutations'

/**
 *
 */
const IMAGE_URL = 'https://buffer-start-page.s3.amazonaws.com/mail.png'

/**
 *
 */
function EmailNotVerified(): JSX.Element {
  const { startPageData } = useStartPageContext()

  const [initiateEmailVerification] = useMutation(
    ACCOUNT_INITIATE_EMAIL_VERIFICATION,
  )

  useEffect(() => {
    initiateEmailVerification({
      variables: {
        channelId: startPageData?.id,
      },
    })
  }, [])

  return (
    <Wrapper>
      <Image src={IMAGE_URL} alt="An image showing a warning sign" />
      <Title>Please verify your email!</Title>
      <Message>
        Click the verification link we’ve sent to your email address to confirm
        it’s really you and publish a Start Page.
      </Message>
    </Wrapper>
  )
}

export default EmailNotVerified
