import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import { FieldSet } from '~/shared/components'

export const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`

export const ThemesWrapper = styled(FieldSet)<{ expanded?: boolean }>`
  padding: 8px;

  & > :last-child {
    margin-bottom: 8px;
  }

  #theme-gallery {
    min-height: 256px;
    max-height: ${(props) => (props.expanded ? 'auto' : '256px')};
    padding: 8px;
    overflow: hidden;
  }
`

export const ShowMore = styled(Button)`
  margin: 8px;
  display: block;
  width: auto;
`

export const FontsTitle = styled(Text)`
  margin: 0px 16px;
  font-size: 14px;
  font-weight: 500;
`
