import React from 'react'
import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

const Count = styled.p`
  width: 100%;
  color: ${grayDarker};
  font-weight: 500;
  font-size: 14px;
  padding: 16px;
`

/**
 *
 */
function Counter({ count }: { count: string | number }) {
  return <Count>{count}</Count>
}

export default Counter
