import styled, { css } from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 127px);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin: 16px 16px 0 16px;
`

export const Button = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
  background: white;
  padding: 16px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 0 4px transparent;

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 0 4px #acb9fd;
    `}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px #acb9fd;
  }
`

export const FontPreview = styled.span<{ font: string; small?: boolean }>`
  font-family: ${(props) => props.font};
  display: block;
  text-align: left;
  font-size: ${(props) => (props.small ? '10px' : '16px')};
  margin-top: ${(props) => (props.small ? '8px' : '0')};
`
