import React, { useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import Modal from '@bufferapp/ui/Modal'

const MAILCHIMP_IMAGE_URL =
  'https://buffer-start-page.s3.amazonaws.com/mailchimp-upgrade@x2.jpg'

interface IUseModal {
  openModal: boolean
  setOpenModal: (openModal: boolean) => void
  ModalPopUp: ({ children }: { children: React.ReactNode }) => JSX.Element
}

export default function useUpgradePathModal(): IUseModal {
  const [openModal, setOpenModal] = useState(false)

  const ModalPopUp = useCallback(
    ({ children }: { children: React.ReactNode }) => {
      const container: Element = document.getElementById(
        'modal-wrapper',
      ) as Element

      return createPortal(
        <Modal
          width="796px"
          background={`url(${MAILCHIMP_IMAGE_URL}) no-repeat right top / cover`}
          closeButton={{
            callback: () => setOpenModal(false),
          }}
        >
          {children}
        </Modal>,
        container,
        'modal',
      )
    },
    [setOpenModal],
  )

  return {
    openModal,
    setOpenModal,
    ModalPopUp,
  }
}
