import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { white, grayLight, grayLighter } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  margin: 0px 16px 16px 16px;
  padding: 16px;
  background-color: ${white};
  border: 1px solid ${grayLight};
  border-radius: 8px;

  & > button {
    border-bottom: 1px solid ${grayLighter};
  }

  & > button:last-child {
    border: none;
    padding-bottom: 0;
  }
`

export const Category = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 4px;
`
