import React from 'react'
import { fontPacks } from '~/shared/constants'
import { useStartPageContext } from '~/modules/pages/state/context'
import { Grid, Button, FontPreview } from './styles'

function FontPicker({
  onSelect,
}: {
  onSelect: (fonts: { header: string; body: string }) => void
}) {
  const { startPageData } = useStartPageContext()
  const { theme } = startPageData || {}

  const headerStyles = theme?.blockStyles.find(
    (block) => block.blockType === 'HEADER',
  )

  return (
    <Grid data-testid="font-pack-picker">
      {fontPacks.map((pack) => {
        const { header, body, id } = pack
        const selected =
          headerStyles?.fontFamily === header &&
          headerStyles.secondaryFontFamily === body
        const prettyHeaderFontName = header.split(',')[0]
        const prettyBodyFontName = body.split(',')[0]
        return (
          <Button
            selected={selected}
            key={id}
            onClick={() =>
              onSelect({
                header,
                body,
              })
            }
            aria-label={
              selected
                ? 'Current Font Pack.'
                : `Change fonts to ${prettyHeaderFontName} and ${prettyBodyFontName}`
            }
          >
            <FontPreview font={header}>{prettyHeaderFontName}</FontPreview>
            <FontPreview font={body} small>
              Example of body text using the font: "{prettyBodyFontName}"
            </FontPreview>
          </Button>
        )
      })}
    </Grid>
  )
}

export default FontPicker
