import { useEffect } from 'react'
import debounce from 'lodash/debounce'
import useSaveTheme from '~/app/state/hooks/useSaveTheme'
import { isEmpty } from '../../AppearanceReducer'

const DEBOUNCE_SAVE_DELAY_MS = 200

function Autosave({ changes }: any) {
  const { saveTheme } = useSaveTheme()

  const debouncedSave = debounce(async (newExperimentData: any) => {
    await saveTheme(newExperimentData)
  }, DEBOUNCE_SAVE_DELAY_MS)

  useEffect(() => {
    if (changes && !isEmpty(changes)) {
      debouncedSave(changes)
    }
  }, [changes])

  return null
}

export default Autosave
