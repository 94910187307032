import React from 'react'

import getEditBlockContent from '~/shared/utils/getEditBlockContent'
import {
  type BlockAPI,
  type BlockSettings,
  getBlockByDatabaseType,
} from '../../blocks'
import { Wrapper, IconWrapper, Label, Content } from './styles'

/**
 *
 */
function EditBlockButton({ block }: { block: any }) {
  const typename = block.__typename

  const blockSettings: BlockAPI | null = getBlockByDatabaseType(typename)
  if (!blockSettings) return null

  const { settings, editButtonSettings } = blockSettings

  const { Component: Icon, color } = (settings as BlockSettings).icon
  const { label, contentField } = editButtonSettings

  const content =
    block && contentField && getEditBlockContent(block, contentField)

  return (
    <Wrapper grayedOut={block.hidden}>
      {Icon && (
        <IconWrapper color={color}>
          <Icon
            data-testid="icon-wrapper"
            title={`A ${label} block`}
            block={block}
          />
        </IconWrapper>
      )}
      <Label type="p">{label}</Label>
      {block.__typename !== 'StartPageBlockMailchimp' && (
        <Content type="p">{content}</Content>
      )}
    </Wrapper>
  )
}

export default EditBlockButton
