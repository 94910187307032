import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { grayDark, grayDarker } from '@bufferapp/ui/style/colors'

import BlockIcon from '../BlockIcon'

export const Wrapper = styled.div<{ grayedOut: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  opacity: ${(props) => (props.grayedOut ? '50%' : '100%')};
`

export const IconWrapper = styled(BlockIcon)`
  width: 40px;
  height: 40px;
  margin-right: 12px;
`

export const Label = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: ${grayDarker};
  margin-right: 5px;
`

export const Content = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: ${grayDark};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  flex: 1;
`
