import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { grayDarker } from '@bufferapp/ui/style/colors'
import { FieldSet } from '~/shared/components'

export const PageViewsWrapper = styled(FieldSet)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0px;
`

export const LinksWrapper = styled(FieldSet)`
  padding: 16px 0px 0px 0px;
  margin-bottom: 16px;
`

export const LinksTitle = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  color: ${grayDarker};
  padding: 0px 16px;
`
