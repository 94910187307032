/**
 *
 */
interface NodeValue {
  text: string
  htmlJson?: string
}

interface JSONTextNode {
  children: NodeValue[]
}

interface Block {
  [key: string]: string
}

export default function getEditBlockContent(
  block: Block,
  contentField: string | null,
): string | null {
  // if the customer hasnt used the new text editor
  // we want to get the value from 'text' instead of 'htmlJson'
  const blockProperty =
    contentField === 'htmlJson' && !block.htmlJson ? 'text' : contentField || ''
  const isJSONText = contentField === 'htmlJson' && block.htmlJson
  const parsedJSONText = isJSONText && JSON.parse(block.htmlJson)

  return parsedJSONText
    ? getfirstSentence(parsedJSONText[0])
    : block[blockProperty] || null
}

const getfirstSentence = (parsedJSONText: JSONTextNode): string => {
  if (parsedJSONText.children.length === 0) return ''
  return parsedJSONText.children
    .map((paragraphNode: NodeValue) => paragraphNode.text)
    .join('')
}
