import { useMutation } from '@apollo/client'
import { MUTATION_REORDER_BLOCK } from '~/app/state/mutations'
import { QUERY_START_PAGE } from '~/modules/pages/state/queries'
import { useStartPageContext } from '~/modules/pages/state/context'
import type { Blocks, StartPage } from '@buffer-mono/sp-display'
import useTracking from '~/shared/hooks/useTracking/useTracking'

type ReorderProps = { startIndex: number; endIndex: number }

const reorderAllBlocks = (
  blocks: Blocks,
  startIndex: number,
  endIndex: number,
): Blocks => {
  const newBlockArray = Array.from(blocks)
  const [removed] = newBlockArray.splice(startIndex, 1)
  newBlockArray.splice(endIndex, 0, removed)
  const updatedOrderArray = newBlockArray.map((item, index) => ({
    ...item,
    order: index,
  }))
  return updatedOrderArray
}

export default function () {
  const { startPageData, setStartPageData } = useStartPageContext()
  const { trackBlockMoved } = useTracking()
  const [saveChangesToDB, { data: response, error: mutationError, loading }] =
    useMutation(MUTATION_REORDER_BLOCK)

  const reorderBlock = ({ startIndex, endIndex }: ReorderProps) => {
    if (startIndex && endIndex && startPageData?.blocks) {
      const { id: pageId, blocks } = startPageData
      const draggedBlock = blocks && blocks[startIndex]
      const reorderedBlocks = reorderAllBlocks(blocks, startIndex, endIndex)

      // TODO: We can use Apollo's optimistic response
      // mechanism instead of doing this. Now that we have
      // an "update" function in place, we can pass
      // an optimistic response next! :)
      setStartPageData({
        ...startPageData,
        hasUnPublishedChanges: true,
        blocks: reorderedBlocks,
      })

      saveChangesToDB({
        variables: {
          blockId: draggedBlock?.id,
          order: endIndex,
          organizationId: startPageData?.organizationId,
        },
        update(cache, { data: { reOrderBlock } }) {
          const allStartPageData = cache.readQuery<StartPage>({
            query: QUERY_START_PAGE,
            variables: {
              pageId,
              organizationId: startPageData?.organizationId,
            },
          })
          cache.writeQuery({
            query: QUERY_START_PAGE,
            data: {
              startPage: {
                ...allStartPageData?.startPage,
                hasUnPublishedChanges: true,
                blocks: reOrderBlock.blocks,
              },
            },
          })
        },
      })
        .then(() => {
          if (draggedBlock) trackBlockMoved(draggedBlock)
        })
        .catch((error) => {
          console.error(error)
          // @todo: trigger an error state & refetch data
        })
    }
    // @todo: trigger an error state here
  }

  return {
    reorderBlock,
    response: response || null,
    mutationError,
    loading,
  }
}
