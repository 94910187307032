import React from 'react'
import DragIcon from '@bufferapp/ui/Icon/Icons/DragIndicator'
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DragUpdate,
} from 'react-beautiful-dnd'

import { useStartPageContext } from '~/modules/pages/state/context'
import useReorderBlock from '~/app/state/hooks/useReorderBlock'
import { DeleteBlockModal } from '~/shared/components'
import useModal from '~/shared/hooks/useModal'
import EditBlockActions from '../EditBlockActions'
import EditBlockButton from '../EditBlockButton'
import { List, ListItem, DragDropIcon } from './styles'

/**
 *
 */
function EditBlockList({
  setEditing,
  setSelectedBlockId,
  deleteBlock,
  selectedBlock,
}: {
  setEditing: (editing: boolean) => void
  setSelectedBlockId: (id: string) => void
  deleteBlock: () => void
  selectedBlock: any
}): JSX.Element {
  const { startPageData } = useStartPageContext()
  const { reorderBlock, mutationError, response, loading } = useReorderBlock()
  const { openModal, setOpenModal, Modal } = useModal()

  const blocks = startPageData?.blocks || []

  const onDragEnd = (result: DragUpdate): void => {
    const { destination, source } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }
    reorderBlock({
      startIndex: source.index,
      endIndex: destination.index,
    })
  }

  const onClickBlock = (blockId: string) => {
    setEditing(true)
    setSelectedBlockId(blockId)
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided): JSX.Element => (
            <>
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {blocks.map((block, index) => (
                  <Draggable
                    key={block.id}
                    draggableId={block.id}
                    index={index}
                    isDragDisabled={block.__typename === 'StartPageBlockHeader'}
                  >
                    {(provided): JSX.Element => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => onClickBlock(block.id)}
                      >
                        <DragDropIcon
                          disabled={block.__typename === 'StartPageBlockHeader'}
                        >
                          <DragIcon />
                        </DragDropIcon>
                        <EditBlockButton block={block} />
                        <EditBlockActions
                          setSelectedBlockId={setSelectedBlockId}
                          block={block}
                          setOpenModal={setOpenModal}
                          shouldDisplayDeleteAction={
                            block.__typename !== 'StartPageBlockHeader'
                          }
                          shouldDisplayHideOrShowAction={
                            block.__typename !== 'StartPageBlockHeader'
                          }
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
              </List>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>

      {openModal && (
        <Modal>
          <DeleteBlockModal
            setOpenModal={setOpenModal}
            deleteBlock={deleteBlock}
          />
        </Modal>
      )}
    </>
  )
}

export default EditBlockList
