import React from 'react'
import { useStartPageContext } from '~/modules/pages/state/context'
import { Preview as PagePreview } from '@buffer-mono/sp-display'

import { useSplitEnabled } from '@bufferapp/features'

/**
 *
 */
export function Preview(): JSX.Element {
  const { startPageData } = useStartPageContext()

  const { isEnabled: isCommunityReportingFlagEnabled } = useSplitEnabled(
    'build-week-2023-community-report-flag-option',
  )

  return (
    <PagePreview
      blocks={startPageData?.blocks || []}
      theme={startPageData?.theme}
      pageId={startPageData?.id}
      domain={startPageData?.domain}
      isCommunityReportingFlagEnabled={isCommunityReportingFlagEnabled}
    />
  )
}

export default Preview
