import styled from 'styled-components'
import { blueLightest, blueDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;

  & > * {
    max-width: 375px;
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  h3,
  p {
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
  }
`

export const Image = styled.img`
  width: 200px;
  height: 178px;
`

export const DomainWrapper = styled.div`
  background-color: ${blueLightest};
  padding: 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px;

  p {
    text-align: left;
    width: max-content;
  }
`

export const Domain = styled.p`
  min-width: 170px;
  margin: 8px 12px;
  color: ${blueDarker};
`
