/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'
import Text from '@bufferapp/ui/Text'

import usePublish from '~/app/state/hooks/usePublish'
import { DomainInput } from '~/shared/components/FormElements'
import { useStartPageContext } from '~/modules/pages/state/context'
import { useAccountContext } from '~/modules/account/state/context'
import updateModalStyle from '../../../utils/updateModalStyle'

import Domain from './Domain'
import Message from './Message'

const Wrapper = styled.div``

const TextWrapper = styled.div`
  text-align: center;

  h3 {
    margin: 64px 0px 8px;
    padding: 0px 16px;
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${grayDarker};
    margin-bottom: 32px;
  }
`

const DomainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 365px;
  margin: 0px auto;

  #domain-input {
    width: 350px;
  }

  & > :last-child {
    margin-bottom: 64px;
  }
`

/**
 *
 */
function StepA({
  withMessage,
  onNext,
}: {
  withMessage: boolean
  onNext: () => void
}) {
  const { hasDomain, startPageData } = useStartPageContext()
  const { publishChanges, loading, error } = usePublish()
  const { moveModalToBack } = updateModalStyle()

  const { accountData, getChannelForStartPage, getUserType } =
    useAccountContext()
  const [chosenDomain, setChosenDomain] = useState('')

  function hasUserReachedLimit(): boolean {
    if (!startPageData) return false

    const userType = getUserType()
    const startPageChannel = getChannelForStartPage(startPageData.id)
    const { canPublishStartPage } = startPageChannel || {}

    return !canPublishStartPage && userType === 'OB_PAID'
  }

  function upgradeChannelLimit() {
    if (!startPageData) return false
    const channelIds = [startPageData.id]
    moveModalToBack()

    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.connectChannelUpgrade, {
      channelIds,
      cta: 'startPage-connectChannelUpgradeModal-confirmAndPay-1',
      modalHeader: 'Heads up! Confirm adding a new channel',
      upgradePathName: 'startPageDomainConfirmation-upgrade',
    })
  }

  useEffect(() => {
    if (!startPageData) return
    const { isPublished } = startPageData

    if (isPublished) {
      onNext()
    }
  }, [startPageData])

  useEffect(() => {
    const hasReachLimit = hasUserReachedLimit()

    if (!hasReachLimit && chosenDomain) {
      publishChanges(chosenDomain)
    }
  }, [accountData])

  const onContinue = useCallback(
    (newDomain: string) => {
      if (hasUserReachedLimit()) {
        setChosenDomain(newDomain)
        upgradeChannelLimit()
        return
      }

      publishChanges(newDomain)
    },
    [publishChanges],
  )

  const domain = startPageData?.domain || ''
  const buttonLabel = loading ? 'Publishing...' : 'Publish Start Page'

  return (
    <Wrapper>
      <TextWrapper>
        <Text type="h3">Publish your Start Page 🚀</Text>
        <Text type="p">
          {hasDomain
            ? 'Your Start Page will be published at your selected domain'
            : "Ready to publish? Choose a domain name and you're all set!"}
        </Text>
      </TextWrapper>
      <DomainWrapper>
        {hasDomain ? (
          <Domain
            value={domain}
            buttonLabel={buttonLabel}
            loading={loading}
            onContinue={onContinue}
          />
        ) : (
          <DomainInput
            id="domain-input"
            domain=""
            onContinue={onContinue}
            buttonLabel={buttonLabel}
            loading={loading}
            error={error}
          />
        )}
      </DomainWrapper>
      {withMessage && <Message />}
    </Wrapper>
  )
}

export default StepA
