import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChevronUp, ChevronDown } from '@bufferapp/ui/Icon'
import Settings from '../settings'
import { Wrapper, Left, CloseTab } from './styles'
import NavBar from './components/NavBar'
import Layout from './components/Layout'
import AddBlock from './components/AddBlock'
import Appearance from './components/Appearance'
import Statistics from './components/Statistics'

export type ActiveTabState =
  | 'layout'
  | 'add'
  | 'appearance'
  | 'settings'
  | 'statistics'
export type SetActiveTabState = React.Dispatch<
  React.SetStateAction<ActiveTabState>
>
export type SetCollapsedState = React.Dispatch<React.SetStateAction<boolean>>

const TABS = ['layout', 'add', 'appearance', 'settings', 'statistics']

/**
 *
 */
function Editor() {
  const [collapsed, setCollapsed] = useState(false)
  const [activeTab, setActiveTab] = useState<ActiveTabState>('layout')

  const { section } = useParams()

  React.useEffect(() => {
    let tab = 'layout'

    const lcSection = section?.toLowerCase()
    if (lcSection && TABS.includes(lcSection)) {
      tab = lcSection
    }

    setActiveTab(tab as ActiveTabState)
  }, [section])

  const onNavBarOptionClick = () => {
    if (collapsed) setCollapsed(false)
  }

  return (
    <Wrapper>
      <CloseTab>
        <button type="button" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ChevronUp /> : <ChevronDown />}
        </button>
      </CloseTab>
      <Left collapsed={collapsed}>
        {activeTab === 'layout' && <Layout />}
        {activeTab === 'add' && <AddBlock />}
        {activeTab === 'appearance' && <Appearance />}
        {activeTab === 'statistics' && <Statistics />}
        {activeTab === 'settings' && <Settings />}
      </Left>
      <NavBar activeTab={activeTab} onNavBarOptionClick={onNavBarOptionClick} />
    </Wrapper>
  )
}

export default Editor
