import React, { type ReactElement, useEffect, useState } from 'react'

import { Header } from '~/shared/components'
import useStatistics from '~/app/state/hooks/useStatistics'
import { useStartPageContext } from '~/modules/pages/state/context'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import type {
  IconLinksBlock,
  LinkBlock,
  ImageLinkBlock,
  FeedPostBlock,
  Blocks,
  FeedBlock,
} from '@buffer-mono/sp-display'
import Counter from './components/Counter'
import PageViews from './components/PageViews'
import LinksTable from './components/LinksTable'
import Link from './components/Link'
import LinkSocial from './components/LinkSocial'
import Tooltip from '@bufferapp/ui/Tooltip'

import { PageViewsWrapper, LinksWrapper, LinksTitle } from './styles'

/**
 *
 */
type LinkedBlock = LinkBlock | ImageLinkBlock | FeedPostBlock

type BlockClicks = {
  block: LinkedBlock
  link: string
  clicks: number
}

type LinkClicks = {
  type: string
  content: string
  url: string
  clicks: number
}

type SocialLinkClicks = {
  url: string
  clicks: number
}

/**
 *
 */
function Statistics(): ReactElement {
  const [linkClicks, setLinkClicks] = useState<LinkClicks[]>([])
  const [socialLinkClicks, setSocialLinkClicks] = useState<SocialLinkClicks[]>(
    [],
  )

  const { statistics, loading, queryStatistics } = useStatistics()
  const { trackStatisticsViewed } = useTracking()
  const { startPageData } = useStartPageContext()

  useEffect(() => {
    trackStatisticsViewed()

    queryStatistics({
      variables: {
        organizationId: startPageData?.organizationId,
        pageId: startPageData?.id,
      },
    })
  }, [])

  useEffect(() => {
    if (!statistics) return

    let blocks = startPageData?.blocks || []
    const { linksClicks } = statistics
    let childrenBlocks: Blocks = []
    blocks.forEach((block) => {
      if (block.__typename === 'StartPageBlockFeed') {
        const feedPosts = (block as FeedBlock).children || []
        childrenBlocks = [...childrenBlocks, ...feedPosts]
      }
    })

    blocks = [...blocks, ...childrenBlocks]

    const linkedBlocks = blocks.filter(
      (el) =>
        el.__typename === 'StartPageBlockButtonLink' ||
        el.__typename === 'StartPageBlockImageLink' ||
        el.__typename === 'StartPageBlockFeedPost',
    )

    const processedLinksClicks = processLinkClicks({
      blocks: linkedBlocks as LinkedBlock[],
      clicks: linksClicks,
    })

    setLinkClicks(processedLinksClicks)

    const socialLinksBlocks = blocks.filter(
      (el) => el.__typename === 'StartPageBlockIconLinks',
    )

    if (socialLinksBlocks) {
      const processedSocialLinksClicks = processSocialLinkClicks({
        blocks: socialLinksBlocks as IconLinksBlock[],
        clicks: linksClicks,
      })

      setSocialLinkClicks(processedSocialLinksClicks)
    }
  }, [statistics])

  const { dailyViews = 0, weeklyViews = 0, allTimeViews = 0 } = statistics || {}

  return (
    <>
      <Header>
        <h2>Statistics</h2>
      </Header>
      <PageViewsWrapper>
        <PageViews count={dailyViews} period="Today" />
        <PageViews count={weeklyViews} period="This Week" />
        <PageViews count={allTimeViews} period="All Time" />
      </PageViewsWrapper>
      <LinksWrapper data-testid="links">
        <LinksTitle type="p">Links with most clicks (Last 30 days)</LinksTitle>
        <LinksTable loading={loading}>
          {linkClicks?.map((link, index) => (
            <tr key={index} data-testid={`link-clicks-${index}`}>
              <td>
                <Tooltip label={link.url}>
                  <Link
                    order={index + 1}
                    type={link.type}
                    content={link.content}
                    url={link.url}
                  />
                </Tooltip>
              </td>
              <td>
                <Counter count={link.clicks} />
              </td>
            </tr>
          ))}
        </LinksTable>
      </LinksWrapper>
      <LinksWrapper data-testid="social-links">
        <LinksTitle type="p">Social links clicks (Last 30 days)</LinksTitle>
        <LinksTable loading={loading}>
          {socialLinkClicks?.map((link, index) => (
            <tr key={index} data-testid={`social-link-clicks-${index}`}>
              <td>
                <Tooltip label={link.url}>
                  <LinkSocial url={link.url} />
                </Tooltip>
              </td>
              <td>
                <Counter count={link.clicks} />
              </td>
            </tr>
          ))}
        </LinksTable>
      </LinksWrapper>
    </>
  )
}

/**
 *
 */
function processLinkClicks({
  blocks,
  clicks,
}: {
  blocks: LinkedBlock[]
  clicks: BlockClicks[]
}): LinkClicks[] {
  const result: LinkClicks[] = []
  blocks.forEach((block) => {
    let data =
      block.__typename === 'StartPageBlockButtonLink'
        ? {
            type: 'Button',
            content: (block as LinkBlock).label,
          }
        : {
            type: 'Image',
            content: (block as ImageLinkBlock).caption,
          }

    switch (block.__typename) {
      case 'StartPageBlockButtonLink':
        data = {
          type: 'Button',
          content: (block as LinkBlock).label,
        }
        break
      case 'StartPageBlockFeedPost':
        data = {
          type: 'Post',
          content: (block as FeedPostBlock).text,
        }
        break

      default:
        data = {
          type: 'Image',
          content: (block as ImageLinkBlock).caption,
        }
        break
    }

    const linksClicks = clicks.filter((el) => el.block?.id === block.id)

    linksClicks.forEach((linkClick) => {
      result.push({
        ...data,
        url: linkClick.link,
        clicks: linkClick.clicks,
      })
    })

    if (block.url && !linksClicks.length) {
      result.push({
        ...data,
        url: block.url,
        clicks: 0,
      })
    }
  })

  return result.sort((a, b) => b.clicks - a.clicks)
}

/**
 *
 */
function processSocialLinkClicks({
  blocks,
  clicks,
}: {
  blocks: IconLinksBlock[]
  clicks: BlockClicks[]
}): SocialLinkClicks[] {
  const result: SocialLinkClicks[] = []

  clicks.forEach((linkClicks) => {
    const block = blocks.find((block) => block.id === linkClicks.block?.id)

    if (block) {
      const { link, clicks } = linkClicks

      result.push({
        url: link,
        clicks,
      })
    }
  })

  // Append all links that did not have any clicks with
  // count set as 0.
  blocks.forEach((block) => {
    const { links } = block

    links?.forEach((link) => {
      const { url } = link

      if (url && !clicks.find((el) => el.link === url)) {
        result.push({
          url,
          clicks: 0,
        })
      }
    })
  })

  return result
}

export default Statistics
