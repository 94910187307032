import React from 'react'
import { ApolloClient, ApolloProvider, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { env } from '~/env'

const URL_API_GATEWAY = env.VITE_URL_API_GATEWAY

console.log('Connecting to API:', URL_API_GATEWAY)

function APIGateway(props: { children: object }) {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: URL_API_GATEWAY,
      credentials: 'include',
      headers: {
        'x-buffer-client-id': 'webapp-start-page',
      },
    }),
  })
  // We currently need to comment the client options out to get things working locally.
  // @todo: Figure out why the options are preventing us connecting to the db locally
  // fetchOptions: {
  //   mode: 'no-cors',
  // },
  // credentials: 'include',
  // headers: {
  //   'x-buffer-client-id': 'webapp-start-page',
  // }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export default APIGateway
