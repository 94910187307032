import React, { useState } from 'react'

import { UpgradePathModalBody, Header } from '~/shared/components'
import { BLOCK_CATEGORIES } from '~/modules/pages'
import AddBlockList from '~/modules/pages/components/AddBlockList'
import { useAccountContext } from '~/modules/account/state/context'
import { getBlockByBlockName } from '~/modules/pages/blocks'
import type { NewBlock } from '~/shared/interfaces'
import useUpgradePathModal from '~/shared/components/UpgradePathModal/hooks/useUpgradePathModal'

import EditPanel from '../EditPanel'

// List of block categories ordered in the way
// we want them displayed on the "Add block" section.
const CATEGORIES = [
  BLOCK_CATEGORIES.RECENTLY,
  BLOCK_CATEGORIES.ESSENTIALS,
  BLOCK_CATEGORIES.MEDIA,
  BLOCK_CATEGORIES.ADVANCED,
]

/**
 *
 */
function AddBlock(): React.JSX.Element {
  const [adding, setAdding] = useState<boolean>(false)
  const [newBlock, setNewBlock] = useState<NewBlock>()
  const { accountData } = useAccountContext()
  const { openModal, setOpenModal, ModalPopUp } = useUpgradePathModal()

  const isNewBuffer = accountData?.currentOrganization?.isOneBufferOrganization

  const onAddBlockClick = (blockName: string): void => {
    const block = getBlockByBlockName(blockName)
    const { type, databaseType, comingSoon, onlyForNewBuffer } = block.settings

    if (comingSoon) return
    if (onlyForNewBuffer && !isNewBuffer) {
      setOpenModal(true)
      return
    }

    setAdding(true)
    setNewBlock({
      type,
      __typename: databaseType,
    })
  }

  const onReturnClick = (): void => {
    setAdding(false)
  }

  return (
    <>
      {!adding && (
        <>
          <Header>
            <h2>What would you like to add to your site?</h2>
          </Header>
          {CATEGORIES.map((category: string, index: number) => (
            <AddBlockList
              key={index}
              category={category}
              onAddBlock={onAddBlockClick}
            />
          ))}
        </>
      )}
      {adding && <EditPanel block={newBlock} onReturnClick={onReturnClick} />}
      {openModal && (
        <ModalPopUp>
          <UpgradePathModalBody setOpenModal={setOpenModal} />
        </ModalPopUp>
      )}
    </>
  )
}

export default AddBlock
