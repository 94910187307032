import styled, { css } from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'
import Tag from '@bufferapp/ui/Tag'

export const Wrapper = styled.div`
  width: 70px;
  background-color: white;
  color: #636363;
`

export const OptionsList = styled.ul`
  list-style: none;
  position: relative;
  height: 100%;
`

interface OptionButtonProps {
  active: boolean
}

export const OptionButton = styled.button<OptionButtonProps>`
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background: transparent;
  color: #636363;
  border: none;
  width: 70px;
  font-size: 12px;
  position: relative;

  svg {
    margin-bottom: 4px;
  }

  &:hover,
  &:focus {
    background-color: #f5f5f5;
    color: ${grayDarker};
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #f5f5f5;
      color: ${grayDarker};
    `}
`

export const StyledTag = styled(Tag)`
  width: 17px;
  height: 17px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  position: absolute;
  top: 5px;
  right: 11px;
`
