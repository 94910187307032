import React, { type ReactElement } from 'react'
import styled from 'styled-components'
import { grayDarker, grayDark } from '@bufferapp/ui/style/colors'
import shortenText from '~/shared/utils/shortenText'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 340px;
  overflow: hidden;
  padding: 8px 0px;
`

export const Icon = styled.div`
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px;
  color: ${grayDarker};
`

export const Network = styled.span`
  font-weight: 500;
  font-size: 14px;
  padding-right: 4px;
`

export const URL = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: ${grayDark};
`

/**
 *
 */
function LinkSocial({ url }: { url: string }): ReactElement {
  return (
    <Wrapper>
      <Info>
        <URL>{shortenText(url, 50)}</URL>
      </Info>
    </Wrapper>
  )
}

export default LinkSocial
