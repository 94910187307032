import React, { useReducer, useEffect } from 'react'

import { Header } from '~/shared/components'
import { useStartPageContext } from '~/modules/pages/state/context'
import ThemeGallery from '~/modules/themes/components/ThemeGallery'
import type { IStartPageTheme } from '@buffer-mono/sp-display'
import { useThemes } from '../../../themes/hooks/useThemes'
import { ButtonColors, Autosave, FontPicker } from './components'
import AppearanceReducer, { type IButtonBlockStyles } from './AppearanceReducer'

import { Wrapper, ThemesWrapper, ShowMore, FontsTitle } from './styles'

/**
 *
 */
function Appearance() {
  const [themesExpanded, setThemesExpanded] = React.useState(false)

  const { startPageData } = useStartPageContext()
  const { queryThemes, themes } = useThemes()

  useEffect(() => {
    queryThemes()
  }, [])

  const [state, dispatch] = useReducer(AppearanceReducer, {
    savedTheme: startPageData?.theme,
    newTheme: {},
    changesMade: false,
    selectedThemeId: startPageData?.theme?.id || 'default',
  })

  const onSelectTheme = (id: string) => {
    const newTheme = themes?.find((theme: IStartPageTheme) => theme.id === id)
    dispatch({ type: 'ADD_THEME', newTheme })
  }

  const onSelectButtonColor = (blockStyles: IButtonBlockStyles) => {
    dispatch({
      type: 'EDIT_BUTTON_COLORS',
      blockStyles: { ...blockStyles },
    })
  }

  const onSelectFont = (fonts: { header: string; body: string }) => {
    dispatch({ type: 'EDIT_FONTS', fonts: { ...fonts } })
  }

  const onToggleThemes = React.useCallback(() => {
    setThemesExpanded((currentValue) => !currentValue)
  }, [setThemesExpanded])

  return (
    <Wrapper>
      <Header>
        <h2>Themes</h2>
      </Header>
      <ThemesWrapper expanded={themesExpanded}>
        <ThemeGallery
          selected={state.selectedThemeId}
          onSelect={onSelectTheme}
          themes={themes}
        />
        <ShowMore
          type="secondary"
          onClick={onToggleThemes}
          label={themesExpanded ? 'Show less' : 'Show more'}
          fullWidth
        />
      </ThemesWrapper>
      <ButtonColors onSelect={onSelectButtonColor} />
      <FontsTitle type="p">Font Packs</FontsTitle>
      <FontPicker onSelect={onSelectFont} />
      <Autosave changes={state.newTheme} />
    </Wrapper>
  )
}

export default Appearance
