import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Button from '@bufferapp/ui/Button'
import usePublish from '~/app/state/hooks/usePublish'
import { useStartPageContext } from '~/modules/pages/state/context'
import { useNotificationContext } from '~/app/state/notifications-context'
import { useAccountContext } from '~/modules/account/state/context'

/** ]
 *
 */
function PublishButton({ onSkipped }: { onSkipped: () => void }): JSX.Element {
  const [searchParams] = useSearchParams()
  const { publishChanges, loading, error } = usePublish()
  const { startPageData, setStartPageData } = useStartPageContext()
  const { setNotificationOptions } = useNotificationContext()
  const { getChannelForStartPage, accountData } = useAccountContext()

  const {
    domain,
    organizationId,
    isPublished,
    hasUnPublishedChanges,
    id: pageId,
  } = startPageData || {}

  useEffect(() => {
    if (error) {
      setNotificationOptions({ message: error.message })
      setStartPageData({ ...startPageData, hasUnPublishedChanges: true })
    }
  }, [error, setNotificationOptions, setStartPageData, startPageData])

  const startPageChannel = getChannelForStartPage(pageId)

  const canPublish =
    isPublished &&
    startPageChannel?.canPublishStartPage &&
    accountData?.hasVerifiedEmail

  const messageCode = searchParams.get('messageCode')
  if (
    messageCode === 'email-verification-success' &&
    hasUnPublishedChanges &&
    accountData?.hasVerifiedEmail
  ) {
    if (domain !== organizationId) publishChanges(domain as string)
    else onSkipped()
  }

  return (
    <Button
      type="primary"
      label={loading ? 'Publishing Changes...' : 'Publish Changes'}
      disabled={!hasUnPublishedChanges || loading}
      onClick={
        canPublish ? (): void => publishChanges(domain as string) : onSkipped
      }
    />
  )
}

export default PublishButton
