import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${grayDarker};
  fill: ${grayDarker};
  margin: 8px 0px 8px 8px;
`

export const ActionsWrapper = styled.div`
  position: absolute;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  button.hidden {
    opacity: 0;
  }

  li:hover &,
  li:focus-within & {
    button.hidden {
      opacity: 1;
    }
  }
`
