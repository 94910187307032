import React from 'react'
import Text from '@bufferapp/ui/Text'

import getTemplatesForCategory from '../../utils/getTemplatesForCategory'
import type { IStartPageTemplate } from '../../index'
import {
  TemplateImage,
  GalleryWrapper,
  Button,
  ImageWrapper,
  EmptyMessage,
} from './styles'

/**
 *
 */
function Gallery({
  templates,
  categories,
  category,
  onSelect,
}: {
  templates: IStartPageTemplate[]
  categories: string[]
  category: string | null
  onSelect: (template: IStartPageTemplate) => void
}) {
  const [filteredTemplates, setFilteredTemplates] =
    React.useState<IStartPageTemplate[]>(templates)

  React.useEffect(() => {
    let result = templates
    if (category && category !== categories[0]) {
      result = getTemplatesForCategory(templates, category)
    }

    setFilteredTemplates(result)
  }, [category])

  const onEnter = React.useCallback(
    (
      event: React.KeyboardEvent<HTMLDivElement>,
      filteredTemplates: IStartPageTemplate,
    ) => {
      const key = event.key || event.keyCode
      if (key === 'Enter' || key === 13) {
        onSelect(filteredTemplates)
      }
    },
    [onSelect],
  )

  return filteredTemplates.length ? (
    <GalleryWrapper>
      {filteredTemplates.map((template) => (
        <Button
          data-testid="template"
          role="button"
          onClick={() => onSelect(template)}
          tabIndex={0}
          key={template.id}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
            onEnter(event, template)
          }
        >
          <ImageWrapper>
            <TemplateImage src={template.thumbnailUrl} alt="" />
          </ImageWrapper>
          <Text type="p">{template.name}</Text>
        </Button>
      ))}
    </GalleryWrapper>
  ) : (
    <EmptyMessage type="p">
      There are no templates for this category yet.
    </EmptyMessage>
  )
}

export default Gallery
