import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  input {
    margin: 0px 0px 12px 0px;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;
  cursor: pointer;
  color: ${grayDark};
  fill: ${grayDark};
`
