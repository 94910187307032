import styled from 'styled-components'
import { InputWrapper } from '~/shared/components/FormElements/DomainInput/styles'
import { Wrapper } from '~/modules/pages/blocks/Video/Tip/styles'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const DomainInputWrapper = styled.div`
  ${InputWrapper} {
    position: relative;
    margin: 8px 0;

    p {
      position: absolute;
      right: 8px;
    }

    input {
      padding-right: 98px;
    }
  }
`

export const Image = styled.img`
  width: 36px;
  height: 36px;
`

export const TipWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  text-decoration: none;
  color: ${grayDarker};
  margin: 24px 0 0;
`
