import React from 'react'
import { Thumbnail } from '~/modules/themes/components/Thumbnail'
import styled from 'styled-components'
import type { IStartPageTheme } from '@buffer-mono/sp-display'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  cursor: pointer;
`

function ThemeGallery({
  selected,
  onSelect,
  themes,
}: {
  selected: string | null
  onSelect: (id: string) => void
  themes: IStartPageTheme[] | undefined
}) {
  if (!themes) return null

  return (
    <Wrapper id="theme-gallery">
      {themes.map((theme: any, index: number) => (
        <Thumbnail
          selected={selected}
          onSelect={onSelect}
          theme={theme}
          key={index}
        />
      ))}
    </Wrapper>
  )
}

export default ThemeGallery
