import { makeExecutableSchema } from '@graphql-tools/schema'

const schema: any = makeExecutableSchema({
  typeDefs: `
  enum StartPageBlockLayout {
    TEXT
    IMG_TEXT
  }

  enum StartPageBlockTypes {
    HEADER
    TEXT
    SUB_HEADING
    BUTTON_LINK
    IMAGE_LINK
    VIDEO
    ICON_LINKS
    FEED
    FEED_POST
    MAILCHIMP
  }

  type StartPage {
    id: ID!
    domain: String
    organizationId: String
    blocks: [StartPageBlock]
  }

  interface StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
  }

  type StartPageMedia {
    url: String
    altText: String
  }

  type StartPageBlockHeader implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Header specific data
    """
    title: String
    description: String
    overlay: Int
    backgroundMedia: StartPageMedia
    logoMedia: StartPageMedia
    layout: String
  }

  type StartPageBlockText implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Text specific data
    """
    text: String
    htmlText: String
    htmlJson: String
  }

  type StartPageBlockMailchimp implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Mailchimp specific data
    """
    htmlText: String
  }

  type StartPageBlockSubHeading implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    SubHeading specific data
    """
    text: String
  }

  type StartPageBlockButtonLink implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Button link specific data
    """
    label: String
    url: String
  }

  type StartPageBlockImageLink implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Image link specific data
    """
    caption: String
    url: String
    media: StartPageMedia
  }

  type StartPageBlockVideo implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Video specific data
    """
    media: StartPageMedia
  }

  type StartPageBlockSpotify implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Spotify specific data
    """
    media: StartPageMedia
    layout: String
  }

  type StartPageBlockFeed implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Feed specific data
    """
    title: String
    children: [StartPageBlock]
  }

  type StartPageBlockFeedPost implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Feed Post specific data
    """
    caption: String
    title: String
    text: String
    label: String
    url: String
    media: StartPageMedia
  }

  type StartPageIconLink {
    url: String
    icon: String
    order: Int
  }

  type StartPageBlockIconLinks implements StartPageBlock {
    id: ID!
    pageId: ID!
    order: Int
    hidden: Boolean
    """
    Icon links specific data
    """
    links: [StartPageIconLink]
  }

  type Account {
    id: ID!
    currentOrganization: AccountOrganization
  }

  type AccountOrganization {
    id: ID!
  }

  type MutationResponse {
    code: String,
    success: Boolean,
    message: String
  }

  type Query {
    startPage(organizationId: String!): StartPage
    account: Account
  }

  input Media {
    altText: String,
    url: String
  }

  input BlockDataInput {
    label: String,
    url: String,
    text: String,
    caption: String,
    media: Media
  }

  input BlockConfigInput {
    type: StartPageBlockTypes,
    data: BlockDataInput
  }

  type Mutation {
    editBlock(blockId: String!, data: BlockDataInput, organizationId: String!): MutationResponse
    deleteBlock(blockId: String!, organizationId: String!): MutationResponse
    addBlock(organizationId: String!, pageId: String!, data: BlockConfigInput): MutationResponse
  }
`,
})

export default schema
