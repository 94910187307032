import React, { useCallback } from 'react'
import Text from '@bufferapp/ui/Text'

import { FieldSet } from '~/shared/components'
import { BlockType, isDarkColor } from '@buffer-mono/sp-display'
import { buttonCombinations } from '~/shared/constants'
import useTracking from '~/shared/hooks/useTracking/useTracking'
import { useStartPageContext } from '~/modules/pages/state/context'
import type { IButtonBlockStyles } from '../../AppearanceReducer'
import ColorPicker from '../ColorPicker'

import { Align, ColorButton, ColorOptions, ColorOption } from './styles'

/**
 *
 */
function ButtonColors({
  onSelect,
}: {
  onSelect: (blockStyles: IButtonBlockStyles) => void
}) {
  const { startPageData } = useStartPageContext()
  const { trackThemeEdited } = useTracking()

  const { blockStyles, id: selectedTheme } = startPageData?.theme || {}
  const selectedStyles = blockStyles
    ? blockStyles.find((el) => el.blockType === 'BUTTON_LINK')
    : null

  const currentColor = selectedStyles?.backgroundColor
  const currentCombo = buttonCombinations.find(
    (el) => el.backgroundColor === currentColor,
  )
  const isCustomColor = !!currentColor && !currentCombo

  const getFontColor = useCallback(
    (color: string) => {
      const darkColor = isDarkColor(color)
      return darkColor ? '#FFFFFF' : '#232323'
    },
    [isDarkColor],
  )

  const onSelectColors = useCallback(
    (color: string, textColor?: string) => {
      if (!color) return

      onSelect({
        blockType: BlockType.BUTTON_LINK,
        backgroundColor: color,
        textColor: textColor || getFontColor(color),
      })

      selectedTheme && trackThemeEdited(selectedTheme)
    },
    [onSelect, getFontColor],
  )

  return (
    <FieldSet data-testid="button-colors">
      <Align>
        <Text htmlFor="foo" type="label" color="gray">
          Button Color
        </Text>
        <ColorOptions>
          <ColorOption selected={isCustomColor}>
            <ColorPicker
              currentColor={isCustomColor ? currentColor : null}
              onSelectColor={onSelectColors}
            />
          </ColorOption>

          {buttonCombinations.map((combo) => {
            const { id, backgroundColor, textColor } = combo

            return (
              <ColorOption key={id} selected={currentColor === backgroundColor}>
                <ColorButton
                  color={backgroundColor}
                  onClick={() => onSelectColors(backgroundColor, textColor)}
                  aria-label={`change background and text color to ${id} for all buttons`}
                />
              </ColorOption>
            )
          })}
        </ColorOptions>
      </Align>
    </FieldSet>
  )
}

export default ButtonColors
