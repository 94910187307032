import React from 'react'
import styled from 'styled-components'

import LinkCopied from '~/modules/publisher/components/LinkCopied'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

/**
 *
 */
function StepC() {
  return (
    <Wrapper>
      <LinkCopied />
    </Wrapper>
  )
}

export default StepC
