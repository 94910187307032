import styled from 'styled-components'
import {
  blue,
  grayLight,
  grayLighter,
  grayDark,
} from '@bufferapp/ui/style/colors'

export const OnboardingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding-bottom: 16px;

  h1 {
    margin: 42px 0 12px 0;
  }

  .subheading {
    margin-bottom: 60px;
  }
`

export const CategoryList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin-bottom: 56px;
  list-decoration: none;
  border-bottom: 1px solid ${grayLight};
`

export const CategoryItem = styled.li<{ selected: boolean }>`
  font-size: 15px;
  line-height: 19px;
  font-weight: ${(props) => (props.selected ? 500 : 'normal')};
  padding: 12px;
  margin: 0px 16px;
  border-bottom: ${(props) => (props.selected ? `2px solid ${blue}` : 'none')};
  cursor: pointer;
`

export const ModalWrapper = styled.div`
  width: 500px;
`

export const TextWrapper = styled.div`
  padding: 24px 16px 16px;
  text-align: center;

  p {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${grayDark};
  }
`

export const ContentWrapper = styled.div`
  background-color: ${grayLighter};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :last-child {
    margin-top: 16px;
  }
`
