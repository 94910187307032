import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@bufferapp/ui/Button'
import Input from '@bufferapp/ui/Input'
import GearIcon from '@bufferapp/ui/Icon/Icons/Gear'

import { BASE_URL } from '~/shared/constants'

import { InputWrapper, IconWrapper } from './styles'

/**
 *
 */
function Domain({
  value,
  loading,
  buttonLabel,
  onContinue,
}: {
  value: string
  loading: boolean
  buttonLabel: string
  onContinue: (domain: string) => void
}) {
  const navigate = useNavigate()

  const onOpenSettings = () => {
    navigate('/settings')
  }

  const FULL_URL = `https://${value}${BASE_URL}`

  return (
    <>
      <InputWrapper>
        <Input
          type="input"
          name="domain"
          placeholder={FULL_URL}
          onChange={(): undefined => {
            return undefined
          }}
          disabled
        />
        <IconWrapper onClick={onOpenSettings}>
          <GearIcon />
        </IconWrapper>
      </InputWrapper>
      <Button
        type="primary"
        onClick={() => onContinue(value)}
        disabled={loading}
        label={buttonLabel}
        fullWidth
      />
    </>
  )
}

export default Domain
